import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';

import testimonialHook from 'hooks/testimonials/testimonials.hook';

import s from './Testimonials.scss';

export default (): JSX.Element => {
    const testimonials = testimonialHook();
    const testimonial = testimonials[ Math.floor(Math.random() * testimonials.length) ];
    const { quote, customer_name } = testimonial.node;

    return (
        <div className={s.testimonial}>
            <div className={s.testimonial__wrapper}>
                <span className={s.testimonial__icon}>
                    <FontAwesomeIcon icon={faQuoteLeft} />
                </span>

                <p className={s.testimonial__comment}>&ldquo;{quote}&rdquo;</p>
                <p className={s.testimonial__author}>{customer_name}</p>
                <p className={s.testimonial__brand}>Rapid Formations Customer</p>
            </div>
        </div>
    );
};
