import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusPurchaseAddressMethod, PurchaseAddressMethodEdges } from './purchase-an-address.interface';

export default (): PurchaseAddressMethodEdges[] => {
    const { allDirectusPurchaseAddressMethod } = useStaticQuery<AllDirectusPurchaseAddressMethod>(graphql`
    query {
      allDirectusPurchaseAddressMethod(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            slug
            title
            body
            icon
            icon_colour
            first_office_preface
            first_office_price {
              value
            }
            first_office_postface
            second_office_preface
            second_office_price {
              value
            }
            second_office_postface
            button_link
            button_text
            button_background_colour
          }
        }
      }
    }
  `);

    return allDirectusPurchaseAddressMethod.edges;
};
