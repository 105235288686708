import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusCallToAction, CallToActionEdge } from './call-to-action.interface';

export default (): CallToActionEdge[] => {
    const { allDirectusCallToAction } = useStaticQuery<AllDirectusCallToAction>(graphql`
    query {
      allDirectusCallToAction {
        edges {
          node {
            directusId
            slug
            heading
            content
            button_text
            button_link
          }
        }
      }
    }
  `);

    return allDirectusCallToAction.edges;
};
