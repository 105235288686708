import React from 'react';

import Button from 'components/common/button/Button';
import Markdown from 'components/common/markdown/Markdown';
import callToActionHook from 'hooks/call-to-action/call-to-action.hook';
import { getFieldBySlug } from 'utils/directus';
import { Props } from './CallToAction.interface';

import s from './CallToAction.scss';

export default ({ slug }: Props): JSX.Element => {
    const { node } = getFieldBySlug(slug, callToActionHook());

    return (
        <div className={s.callToAction}>
            <Markdown className={s.callToAction__heading} source={node.heading} container />
            <Markdown className={s.callToAction__content} source={node.content} container />

            <Button
                role="primary"
                className={s.callToAction__button}
                to={node.button_link}
            >
                {node.button_text}
            </Button>
        </div>
    );
};
